'use client';
import React, { useEffect, useState } from 'react';

import { useSetAtom, useAtomValue } from 'jotai';

import { GenericVehicleListingSubset } from '@core/Actions/Vehicle/ExtractGenericVehicleListingSubset.action';
import {
  addToFavouritesAtom,
  favouritesAtom,
  removeFromFavouritesAtom,
} from '@core/Atoms/Favourites/Favourites.atom';
import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { Tenant } from '@core/Entities/App/Tenants.entity';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';
import { HeartActive } from '@gds/Icons/Paths/HeartActive';
import { HeartActiveRac } from '@gds/Icons/Paths/HeartActiveRac';
import { HeartDefault } from '@gds/Icons/Paths/HeartDefault';
import { HeartDefaultRac } from '@gds/Icons/Paths/HeartDefaultRac';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

import { generateFavouritesTrackingData } from './FavouritesButton.tracking';
import { revalidateFavourites } from './RevalidateFavourites';

export const FavouritesButton = ({
  genericVehicleListings,
  pageType = '',
  tenant = undefined,
}: {
  genericVehicleListings: GenericVehicleListingSubset | Vehicle;
  pageType?: string;
  tenant?: Tenant;
}) => {
  const heycarId = genericVehicleListings?.heycarId;
  const addToFavourites = useSetAtom(addToFavouritesAtom);
  const removeFromFavourites = useSetAtom(removeFromFavouritesAtom);
  const favs = useAtomValue(favouritesAtom);
  const userId = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const formState = useAtomValue(formStateAtom);

  const [isFavourite, setIsFavourite] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    if (favs?.length) {
      const hasFavouriteStatus = favs.some((vehicleId: string) => vehicleId === heycarId);
      setIsFavourite(hasFavouriteStatus);
    }
  }, [favs]);

  const preventClick = (e: React.MouseEvent<HTMLDivElement | HTMLOrSVGElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClick = async () => {
    if (isFavourite) {
      removeFromFavourites(heycarId);
      setIsFavourite(!isFavourite);
    } else {
      addToFavourites({ heycarId });
      setIsFavourite(!isFavourite);
      const {
        addFavourites: { event, context },
      } = await generateFavouritesTrackingData({
        searchParams,
        userId,
        formState,
        listingId: heycarId,
        genericVehicleListings,
        pageType,
      });
      trackCustomEvent({ event, context });
    }

    revalidateFavourites();
  };

  if (!hasMounted) {
    return null;
  }

  return (
    <div onClick={preventClick} data-test-id="favourites-button">
      {hasMounted && isFavourite ? (
        tenant === 'rac' ? (
          <HeartActiveRac onClick={handleClick} />
        ) : (
          <HeartActive onClick={handleClick} />
        )
      ) : tenant === 'rac' ? (
        <HeartDefaultRac onClick={handleClick} />
      ) : (
        <HeartDefault onClick={handleClick} />
      )}
    </div>
  );
};
