/* eslint-ignore @typescript-eslint/naming-convention */

'use client';

import { useEffect } from 'react';

import { useAtom, useAtomValue } from 'jotai';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { scrollToFinanceAtom } from '@core/Atoms/Pdp/ScrollToFinance.atom';
import { tenantAtom } from '@core/Atoms/Tenant/Tenant.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { currentVehicleIdAtom } from '@core/Atoms/Vehicle/CurrentVehicleId.atom';
import { debounce } from '@core/Utils/Debounce';
import Button from '@gds/Button/Button';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

import { generateScrollToFinanceTrackingData } from './ScrollToFinance.tracking';

import styles from './ScrollToFinance.module.css';

interface ScrollToFinanceProps {
  string: string | JSX.Element;
  pageType?: string;
  index?: number | null;
}

export const ScrollToFinance = ({ string, pageType, index }: ScrollToFinanceProps) => {
  const [shouldScrollToFinance, setShouldScrollToFinance] = useAtom(scrollToFinanceAtom);
  const userId = useAtomValue(userIdAtom);
  const formState = useAtomValue(formStateAtom);
  const vehicle = useAtomValue(currentVehicleAtom);
  const heycarId = useAtomValue(currentVehicleIdAtom);
  const tenant = useAtomValue(tenantAtom);

  //tenant && vehicle?.pricing?.hasFinance

  const scrollToFinancingComponent = () => {
    const financingComponent = document.getElementById(
      tenant ? 'financing-calculator-title' : 'financing-calculator',
    );
    if (financingComponent) {
      let offset = 80;
      if (window.innerWidth >= 1024) {
        offset = 120;
      }
      const topOffset = financingComponent.getBoundingClientRect().top;
      window.scroll({
        top: topOffset - offset, // Adjust 100px to your desired offset
        behavior: 'smooth',
      });
    }
  };

  const debouncedResetScrollToFinance = debounce(() => {
    setShouldScrollToFinance(false);
  }, 1500);

  const handleButtonClick = () => {
    if (pageType) {
      const { event, context } = generateScrollToFinanceTrackingData({
        userId,
        formState,
        listingId: heycarId,
        vehicle,
        pageType,
        index,
      });
      trackCustomEvent({ event, context });
      setShouldScrollToFinance(true);
      scrollToFinancingComponent();
    }
  };

  useEffect(() => {
    // Call scrollToFinancingComponent when the component is mounted
    if (shouldScrollToFinance) {
      scrollToFinancingComponent();
    }
  }, [shouldScrollToFinance]);

  useEffect(() => {
    if (shouldScrollToFinance) {
      debouncedResetScrollToFinance();
    }
  }, [shouldScrollToFinance]);

  return (
    <Button
      variant="link"
      data-source={!!pageType}
      className={styles.financeOptionsLink}
      onClick={handleButtonClick}
    >
      {string}
    </Button>
  );
};
