'use client';
import { ChangeEvent } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import Link from 'next/link';

import {
  addToCompareAtom,
  compareAtom,
  removeFromCompareAtom,
} from '@core/Atoms/CarCompare/Compare.atom';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { Checkbox } from '@gds/Checkbox/Checkbox';
import { Compare } from '@gds/Icons/Paths/Compare';
import { Typography } from '@gds/Typography/Typography';

import { toastManagerAtom } from '../Toast/ToastManager';

import styles from './CompareCheckbox.module.css';

interface CompareCheckboxProps {
  heycarId: string;
  locale: Locale;
  strings: Record<string, string>;
}

const MAX_COMPARE = 3;

export const CompareCheckbox = ({ heycarId, locale, strings }: CompareCheckboxProps) => {
  const addToast = useSetAtom(toastManagerAtom);
  const selectedForCompare = useAtomValue(compareAtom);
  const addToCompare = useSetAtom(addToCompareAtom);
  const removeFromCompare = useSetAtom(removeFromCompareAtom);
  const isCheckedInState = selectedForCompare.includes(heycarId);
  const isFull = selectedForCompare.length === MAX_COMPARE;

  //TODO: this should go to hook
  const handleChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const isTryAdd = e.target.checked && !isCheckedInState;
    const numberAtStart = selectedForCompare.length;
    const isSelectionFull = numberAtStart === MAX_COMPARE;

    if (!e.target.checked && isCheckedInState) {
      removeFromCompare(heycarId);
    }
    if (isTryAdd && !isSelectionFull) {
      addToCompare(heycarId);
      if (numberAtStart === 1)
        addToast({ children: strings.maxWarning, variant: 'dark', icon: <Compare /> });
    }
    if (isTryAdd && isSelectionFull) {
      addToast({
        children: (
          <span>
            {strings.maxError}{' '}
            <Link className={styles.link} href={`/${locale}/car-compare`}>
              {strings.viewComparison}
            </Link>
          </span>
        ),
        variant: 'dark',
        icon: <Compare />,
      });
    }
  };

  return (
    <div
      className={styles.wrpr}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Typography Component="label" variant="subheading3">
        <Checkbox
          id={`car-compare-cb-${heycarId}`}
          checked={isCheckedInState}
          onChange={handleChanged}
          disabled={isFull && !isCheckedInState}
        />
        {strings.compare}
      </Typography>
    </div>
  );
};
