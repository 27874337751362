'use client';
import { useAtomValue } from 'jotai';

import Link from 'next/link';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { generateCtaClickedTracking } from '@tracking/CtaClicked.tracking';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

import { VehicleTileAnchorProps } from './VehicleTile.entities';

const VehicleTileAnchor = ({
  className,
  heycarId,
  locale,
  shouldOpenInNewTab,
  tenant,
  trackingLocation,
  children,
}: VehicleTileAnchorProps) => {
  const href = `/${!tenant ? `${locale}/` : ''}auto/${heycarId}`;
  const userId = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const formState = useAtomValue(formStateAtom);

  const handleClick = () => {
    const { event, context } = generateCtaClickedTracking({
      searchParams,
      userId,
      formState,
      ctaType: 'link',
      ctaWording: 'vehicle tile',
      ctaLocation: trackingLocation,
      ctaUrl: href,
    });

    trackCustomEvent({ event, context });
  };

  return (
    <Link
      className={className}
      href={href}
      onClick={handleClick}
      prefetch={false}
      target={shouldOpenInNewTab ? '_blank' : undefined}
    >
      {children}
    </Link>
  );
};

export { VehicleTileAnchor };
