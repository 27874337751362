'use client';
import { ReactNode } from 'react';

import { FilterKey } from '@core/Entities/Search/QueryParams.entity';
import { Filter } from '@core/Entities/Search/SearchFilters/Filter.entity';
import { VehicleSpec } from '@core/Entities/Vehicle/VehicleSpec.entity';
import { getFilterFromArray } from '@core/Utils/SearchFilters/GetFilterFromArray';
import { Pill } from '@gds/Pill/Pill';

import styles from './VehicleSpecLabels.module.css';

const FILTER_PRIORITY = ['fuel-type', 'body-type', 'gear-box'] as const;

const filterToSpecMap = {
  'fuel-type': 'fuelType',
  'body-type': 'bodyType',
  'gear-box': 'gearbox',
};

const DynamicVehicleSpec = ({
  vehicleSpec,
  strings,
  activeFilters,
}: {
  vehicleSpec: VehicleSpec;
  strings: Record<string, string>;
  activeFilters: Filter[];
}) => {
  let activeFilter: (typeof FILTER_PRIORITY)[number] = FILTER_PRIORITY[0];

  for (let i = 0; i < FILTER_PRIORITY.length; i++) {
    const filter = FILTER_PRIORITY[i];

    const filterValue = getFilterFromArray(filter as FilterKey, activeFilters);

    if (!filterValue?.value) {
      activeFilter = filter;
      break;
    }
  }
  const pillLabel = vehicleSpec[filterToSpecMap[activeFilter] as keyof VehicleSpec] as ReactNode;
  return <Pill className={styles.label}>{strings[pillLabel as string] || pillLabel}</Pill>;
};

export default DynamicVehicleSpec;
