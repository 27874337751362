'use client';
import { useEffect, useRef, useState } from 'react';

import { SkeletonLoader } from '../Loading/SkeletonLoader';

import { generateSrcSet } from './GenerateSrcSet';

import styles from './HeycarImage.module.css';

type Props = {
  src: string;
  alt: string;
  sizes: string;
  className?: string;
  forceVisible?: boolean;
  loader?: ({ src, width }: { src: string; width: number }) => string;
  preload?: boolean;
  isInactive?: boolean;
};

export const HeycarImage = ({
  src,
  alt,
  sizes,
  className,
  forceVisible = false,
  loader = ({ src }) => src,
  preload = false,
  isInactive = false,
}: Props) => {
  const [isInViewport, setIsInViewport] = useState<boolean | undefined>(forceVisible);
  const [hasLoaded, setHasLoaded] = useState<boolean>(true);
  const $imageRef = useRef<HTMLImageElement>(null);
  const $containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isComplete = $imageRef?.current?.complete;
    const hasHeight = $imageRef?.current?.naturalHeight !== 0;
    if (isComplete && hasHeight) {
      setHasLoaded(true);
    } else {
      setHasLoaded(false);
    }
  }, [$imageRef?.current?.complete, $imageRef?.current?.naturalHeight]);

  useEffect(() => {
    if (!$containerRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) setIsInViewport(true);
      },
      { root: null, rootMargin: '0px', threshold: 0 },
    );

    observer.observe($containerRef.current);

    return () => {
      if (!$containerRef.current) return;
      observer.unobserve($containerRef.current);
    };
  }, [$containerRef.current]);

  return (
    <>
      {!hasLoaded && isInViewport && <SkeletonLoader width={'100%'} height={'100%'} />}
      <div
        ref={$containerRef}
        className={styles.container}
        data-has-loaded={hasLoaded}
        data-is-visible={isInViewport}
        key={src}
      >
        {isInViewport && (
          <img
            className={`${className} ${styles.image}`}
            ref={$imageRef}
            src={src}
            srcSet={generateSrcSet(src, loader)}
            sizes={sizes}
            alt={alt}
            onLoad={() => setHasLoaded(true)}
            fetchPriority={preload ? 'high' : undefined}
            loading={preload ? 'eager' : 'lazy'}
            data-is-inactive={isInactive}
          />
        )}
      </div>
    </>
  );
};
