import { DEFAULT_LOCALE, DE_LOCALE, FR_LOCALE } from '../../Constants/Locale.constants';

const DefaultImgAlt = {
  [DEFAULT_LOCALE]: 'Main listing image',
  [FR_LOCALE]: "Image de l'annonce principale",
  [DE_LOCALE]: 'Hauptbild der Vorlage',
} as const;

export type DefaultImgAlt = typeof DefaultImgAlt;
export type DefaultImgAltKey = keyof DefaultImgAlt;

export const getDefaultImgAlt = (locale: DefaultImgAltKey): string => {
  return DefaultImgAlt[locale] || DefaultImgAlt[DEFAULT_LOCALE];
};
