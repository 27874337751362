'use client';

import { atom } from 'jotai';

import { atomWithStorage } from 'jotai/utils';
import Cookies from 'js-cookie';

import { createCookieStorage } from '../../Utils/CookieStorage';

export const compareAtom = atomWithStorage(
  `compare-${Cookies.get('locale')}`,
  [],
  createCookieStorage<string[]>([]),
);

export const removeFromCompareAtom = atom(null, (_, set, heycarId) => {
  set(compareAtom, (currentCompare: string[]) =>
    currentCompare.filter((vehicleId: string) => vehicleId !== heycarId),
  );
});

export const addToCompareAtom = atom(null, (get, set, heycarId: string) => {
  const currentCompare = get(compareAtom);
  if (currentCompare.length < 3) {
    set(compareAtom, (currentCompare: string[]) => [...(currentCompare || []), heycarId]);
  }
});
