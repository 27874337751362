/* eslint-disable @typescript-eslint/naming-convention */
'use server';
import { Vehicle } from '../../Entities/Vehicle/Vehicle.entity';

import { GenericVehicleListingSubset } from './ExtractGenericVehicleListingSubset.action';

export const extractVehicleListingFeatureSubset = async (
  vehicle: GenericVehicleListingSubset | Vehicle,
) => {
  return {
    offers: {
      activeOffer: vehicle.offers?.activeOffer,
      promotions: vehicle.offers?.promotions,
      offers: vehicle.offers?.offers,
    },
    ecom: {
      isEcom: vehicle.ecom.isEcom,
    },
    eligibleProducts: vehicle.eligibleProducts || [],
    details: {
      isFeatured: vehicle.details.isFeatured,
      lastUpdatedAt: vehicle.details.lastUpdatedAt,
      newlyListed: vehicle.details.newlyListed,
      isFactoryApproved: vehicle.details.isFactoryApproved,
      createdAt: vehicle.details.createdAt,
      status: vehicle.details.status,
    },
    monthlyPrice: {
      // @ts-expect-error // .monthlyPrice exists on the root of GenericVehicleListingSubset .finance exists on Vehicle
      has_hp: vehicle.monthlyPrice?.has_hp || vehicle.finance?.product === 'hp',
      // @ts-expect-error // .monthlyPrice exists on the root of GenericVehicleListingSubset .finance exists on Vehicle
      has_pcp: vehicle.monthlyPrice?.has_pcp || vehicle.finance?.product === 'pcp',
    },
    // @ts-expect-error // .images doesn't exist on GenericVehicleListingSubset but does on Vehicle
    num_images: (vehicle.num_images ? vehicle.num_images : vehicle.images?.length) || 0,
    pricing: {
      priceRating: vehicle.pricing.priceRating,
      isReducedPrice: vehicle.pricing.isReducedPrice,
      hasFinance: vehicle.pricing.hasFinance,
      discount: vehicle.pricing.discount,
    },
  };
};

export type VehicleListingFeatureSubset = Awaited<
  ReturnType<typeof extractVehicleListingFeatureSubset>
>;
